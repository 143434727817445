<template>
  <div class="project wrapper hei">
    <!-- 项目套餐 列表 -->
    <ul class="container slide" v-if="projectList && projectList.length">
      <li
        class="card flex-start relative"
        v-for="(item, index) in projectList"
        :key="index"
      >
        <img class="ticket-img mr-3x" :src="item.skuImages" />
        <div class="card-text-wrap">
          <p class="font-xl text-1 line-height">
            {{ item.name }}
          </p>
          <div class="font-l gray flex-start line-height">
            <span>含：</span>
            <span
              v-if="item.relationProjectType == 2"
              class="text-1"
              style="max-width: 340px;"
            >
              {{ item.projectInfoList ? item.projectInfoList.join(",") : "" }}
            </span>
            <span v-if="item.relationProjectType == 1">全部项目</span>
          </div>
          <p
            v-if="item.buyNotice && item.buyNotice != 'null'"
            class="gray font-l notice"
            @click="openDialog(item)"
          >
            <a-icon class="gray" type="exclamation-circle" theme="filled" />
            购买须知
          </p>
          <div class="line-height">
            <span class="red mr-2x">{{ item.sellingPrice | money }}</span>
            <number-box
              @plusClick="next => plusClick(item, next)"
              @minusClick="next => minusClick(item, next)"
              class="num-box"
            ></number-box>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="container slide">
      <a-empty class="mt-4x font-l"></a-empty>
    </div>
    <!-- 底部按钮 -->
    <div class="footer back-footer flex-between container">
      <p class="font-l">
        已选 {{ count }} 项 合计：<span class="red">{{
          totalMoney | money
        }}</span>
      </p>
      <a-button type="primary" class="btn" @click="sureBuy">确定选择</a-button>
    </div>
    <!-- 购票须知  弹窗 -->
    <basics v-model="showDialog" :hideDefaultHeader="true">
      <div slot="header" class="header flex-start pb-2x bd-b font-l text-1">
        {{ dialogData.name }}
      </div>
      <div
        slot="content"
        class="mt-4x font-xl"
        style="max-height:1000px;overflow-y: auto;"
      >
        <div
          v-html="dialogData.buyNotice"
          v-if="dialogData.buyNotice && dialogData.buyNotice != 'null'"
        ></div>
        <div v-else>暂无数据</div>
      </div>
    </basics>
    <!-- 查询结果弹窗 -->
    <Result :vis.sync="resultDialog" text="请选择要购买的项目套餐"></Result>
  </div>
</template>
<script>
import NumberBox from "@/components/common/number-box";
import Basics from "@/components/dialog/basics";
import { productSelfProjectPackage } from "../../api/search";
import { createCart, manyProduct } from "../../api/cart";
import Result from "../../components/dialog/result.vue";
import { mapActions } from "vuex";

export default {
  name: "Project",
  components: {
    NumberBox,
    Basics,
    Result
  },
  props: {},
  data() {
    return {
      showDialog: false,
      projectList: [],
      dialogData: {}, // 可购买的项目套餐
      count: 0, // 已选多少项
      totalMoney: 0, // 价格总额
      resultDialog: false
    };
  },
  created() {
    // 获取项目套餐列表
    this.getProjectList();
    this.$speak("请选择需要购买的项目套餐");
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    // 项目套餐列表
    getProjectList() {
      productSelfProjectPackage()
        .then(res => {
          res.forEach(item => {
            item.count = 0;
          });
          this.projectList = res || [];
        })
        .catch(() => {});
    },
    // 购买须知弹窗
    openDialog(item) {
      this.dialogData = {
        buyNotice: item.buyNotice,
        name: item.name
      };
      this.showDialog = true;
    },
    // 加1
    plusClick(item, next) {
      item.count += 1;
      next();
      this.totalMoney = this.totalMoney + item.sellingPrice;
      this.count += 1;
    },
    // 减1
    minusClick(item, next) {
      item.count -= 1;
      next();
      this.totalMoney = this.totalMoney - item.sellingPrice;
      this.count -= 1;
    },
    sureBuy() {
      // this.$router.push({ name: "project-order" });
      if (this.count <= 0) {
        this.$speak("请选择要购买的项目套餐");
        this.resultDialog = true;
        return;
      }
      // 设置支付成功后，返回项目套餐页面
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: "/project"
      });
      let arr = [];
      this.projectList.forEach(item => {
        if (item.count > 0) {
          arr.push({
            productId: item.productId,
            spuId: item.spuId,
            buyNum: item.count
          });
        }
      });

      // 生成购物车id
      createCart({
        buyChannels: 8, // 购买渠道
        businessCategory: 1, // 业务类型
        cartProductList: arr
      })
        .then(res => {
          this.goToOrder(res.id);
        })
        .catch(() => {});
    },
    // 跳转 - 确认订单
    goToOrder(carId) {
      const params = {
        id: carId, // 生成的购物id
        buyChannels: 8, // 购买渠道
        businessCategory: 1, // 订单业务类型
        productList: []
      };
      this.projectList.forEach(item => {
        params.productList.push({
          productId: item.productId,
          spuId: item.spuId,
          buyNum: item.count
        });
      });
      manyProduct(params)
        .then(() => {
          // this.$store.dispatch("cart/SetCartId", carId);
          this.$store.dispatch("order/setCartList", { id: carId });
          // this.$store.dispatch("common/setPrintingRequired", false); // 不需要显示打印组件
          this.$router.push({
            path: "/project-order",
            query: { backUrl: "/project", print: "project" }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.ticket-img {
  width: 100px;
  height: 100px;
  border-radius: @2x;
}
.card-text-wrap {
  max-width: 550px;
  .line-height {
    line-height: 34px;
  }
  .notice {
    width: 180px;
  }
  .num-box {
    position: absolute;
    right: @3x;
    bottom: @2x;
  }
}

// 弹窗图片
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}

/deep/.ant-table {
  font-size: 24px;
}
</style>
