<!--
  @name: 基础弹窗
  @author: 罗竞旭
  v-model: 弹窗的 开与关
  @props: {
    // 弹窗宽度，默认800px
    width: {
      type: Number,
      default: 800
    },
    // header - 数据
    headerData: {
      type: Object,
      default: () => {
        return {
          imgUrl:
            "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2475530841,3457253226&fm=26&gp=0.jpg",
          title: "两大两小门票+酒店+餐饮+300游乐套餐",
          subTitle: "门票类型：优惠票",
          newPrice: "￥699",
          oldPrice: "￥999"
        };
      }
    },
    // 是否 隐藏 默认的 header slot
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
  }
  @slot: {
    header(slot-name): 弹窗的头部内容，默认是按购票页面，购票须知的弹窗 投票布局，内容如果不一样，直接覆盖
    content：弹窗内容
    footer: 弹窗底部
  }
  @event: {
    close: 关闭弹窗(关联v-model)
  }
-->

<template>
  <a-modal
    centered
    :width="width"
    :visible="visible"
    :footer="null"
    @cancel="cancel"
    :mask-closable="maskClosable"
    :closable="showCloseIcon"
    :body-style="{
      'max-height': '1300px'
    }"
  >
    <!-- 自定义右上角关闭按钮 -->
    <a-icon
      class="gray font-xxl"
      type="close-circle"
      theme="filled"
      slot="closeIcon"
    ></a-icon>

    <!-- 自定义头部，默认显示了一些内容 -->
    <slot name="header">
      <div v-if="!hideDefaultHeader" class="header flex-start pb-2x bd-b">
        <img class="header-img" :src="headerData.productImage" />
        <div class="font-xl ml-3x">
          <p>{{ headerData.productName }}</p>
          <p class="gray mid-text">{{ headerData.playDate | date }}</p>
          <span class="red mr-2x">{{
            headerData.settlementPrice | money
          }}</span>
          <span class="text-dlt gray">{{
            headerData.sellingPrice | money
          }}</span>
        </div>
      </div>
    </slot>

    <!-- 主体内容 -->
    <slot name="content"></slot>

    <!-- 底部按钮 -->
    <!-- 需要底部的边框线的：请给你的slot根元素加上 set-border类名 -->
    <slot name="footer"></slot>
  </a-modal>
</template>
<script>
export default {
  name: "BasicsDialog",
  components: {},
  model: {
    prop: "visible",
    event: "close"
  },
  props: {
    // 用于自定义 v-modal
    visible: {
      type: Boolean,
      default: false
    },
    // 弹窗的宽度
    width: {
      type: [Number, String],
      default: 800
    },
    // 弹窗header的默认显示内容
    headerData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否 隐藏默认的 header slot
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    // 点击蒙层，是否允许关闭
    maskClosable: {
      type: Boolean,
      default: true
    },
    // 是否显示右上角，关闭icon
    showCloseIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    cancel() {
      this.$emit("close", false);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.header-img {
  width: 160px;
  height: 160px;
  border-radius: @2x;
}
.mid-text {
  line-height: 60px;
}
// modal 右上角关闭按钮距离
/deep/.ant-modal-close {
  top: @3x;
  right: @3x;
}

/deep/.set-border {
  margin: 0 -24px;
  border-top: 1px solid @border;
}
</style>
